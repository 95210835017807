<template>
  <div>
    <img :src="imgData" alt="">
  </div>
</template>
<script>
import { sendWSPush } from '@/utils/websokcet.js'
export default {
  data() {
    return {
      imgData: '',
      socket: null
    }
  },
  mounted() {
    // 注册监听事件
    window.addEventListener('onmessageWS', this.getsocketData)
    sendWSPush({ code: 'LOGIN_QRCODE' })
  },
  methods: {
    getsocketData(e) {
      const data = e && e.detail.data
      if (this.isJson(data)) {
        const res = JSON.parse(data)
        if (res.code === 'LOGIN_QRCODE') {
          this.getQRCode(res.image)
        } else if (res.code === 0 && res.access_token) {
          this.$message.success('登录成功')
          this.$store
            .dispatch('user/setToken', {
              token: 'Bearer ' + res.access_token,
              remember: false
            })
            .then(() => {
              this.goHome()
            })
        }
      }
    },
    /* 跳转到首页 */
    goHome() {
      const query = this.$route.query
      const path = query && query.from ? query.from : '/'
      this.$router.push(path).catch(() => { })
    },
    getQRCode(data) {
      this.imgData = 'data:image/jpg;base64,' + data
    },
    isJson(data) {
      try {
        JSON.parse(data)
        return true
      } catch {
        return false
      }
    }
  }
}
</script>
<style scoped>
div img {
  width: 300px;
  height: 280px;
}
</style>
